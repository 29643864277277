import React, { useState, useEffect, useRef } from 'react';

import './transition-component.scss';

const transitionComponent = ({ children, rootMargin } : {
  children: React.ReactNode, rootMargin: string}) => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      }, { rootMargin },
    );

    if (ref && ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref && ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`transition-component${isVisible ? ' is-visible' : ''}`}
    >
      {children}

    </div>
  );
};

export default transitionComponent;
