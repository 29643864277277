import React from 'react';
import LargeTeaser from '../components/large-teaser';
import TransitionComponent from '../components/transition-component';

import Layout from '../components/layout';
import SEO from '../components/seo';
import MenuList from '../components/menu-list';

import './home.scss';

const IndexPage = () => (
  <Layout>
    <SEO title="Skin Deep | Race + Culture" />
    <article className="article">
      <div className="article-inner">
        <LargeTeaser />
      </div>
    </article>
    <div className="homepage-content">
      <div className="homepage-content-inner">
        <TransitionComponent rootMargin="100px">
          <div className="homepage-slogan">
            Skin Deep makes space for Black creatives and creatives of colour to
            work towards justice.
          </div>
        </TransitionComponent>
        <div className="homepage-list">
          <MenuList />
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
