import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './large-teaser.scss';

const LargeTeaserPure = ({ data: { wpSeason, wpPost, wpEvent } } : any) => {
  const {
    title, slug, featuredImage, featuredOnHomepage, seoFields,
  } = wpSeason || wpPost || wpEvent || {};
  let slugPrefix = '';
  if (wpSeason) {
    slugPrefix = 'seasons/';
  } else if (wpPost) {
    slugPrefix = 'articles/';
  } else if (wpEvent) {
    slugPrefix = 'events/';
  }

  const image = getImage(featuredImage?.node?.localFile);
  const videoUrl = featuredOnHomepage?.homepageVideo?.localFile?.publicURL;

  const seoTitle = seoFields?.customSeoTitle;

  const targetTitle = seoTitle || title;

  return (
    <div className="large-teaser">
      <div className="large-teaser-image">
        {videoUrl ? (
          <div className="large-teaser-image-video">
            <video src={videoUrl} muted autoPlay loop />
          </div>
        ) : (
          <GatsbyImage image={image} alt="" />
        )}
      </div>
      <div
        className={`large-teaser-title${
          targetTitle.length > 16 ? ' large-teaser-title--long' : ''}`}
      >
        { targetTitle }
      </div>
      <div className="large-teaser-view">
        <Link to={slugPrefix + slug}>Dive in</Link>
      </div>
    </div>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
    query LargeTeaser {
      wpSeason(featuredOnHomepage: {isFeatured: {eq: true}}) {
        slug
        title
        seoFields {
          customSeoTitle
        }
        featuredOnHomepage {
          homepageVideo {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1470
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  aspectRatio: 1.7375
                )
              }
            }
          }
        }
      }
      wpPost(featuredOnHomepage: {isFeatured: {eq: true}}) {
        slug
        title
        seoFields {
          customSeoTitle
        }
        featuredOnHomepage {
          homepageVideo {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1470
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  aspectRatio: 1.7375
                )
              }
            }
          }
        }
      }
      wpEvent(featuredOnHomepage: {isFeatured: {eq: true}}) {
        slug
        title
        seoFields {
          customSeoTitle
        }
        featuredOnHomepage {
          homepageVideo {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1470
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  aspectRatio: 1.7375
                )
              }
            }
          }
        }
      }
    }
    `}
    render={(data) => <LargeTeaserPure data={data} />}
  />
);
